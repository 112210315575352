import React from "react";

const Modal = ({ title, content, visible, onCancel, onOk }) => {
  if (!visible) {
    return null;
  }

  return (
    <>
      {visible && <div className="overlay" onClick={onCancel}></div>}
      <div className="modal-custom p-3 bg-white border shadow">
        <div className="">
          <div className="">
            <h4 className="fw-bold mb-3">{title}</h4>
          </div>
          <div className="mb-3">{content}</div>
          <div className="float-end">
            <button className="btn btn-dark me-3" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-danger" onClick={onOk}>
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
