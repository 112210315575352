import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import Table from "../../../component/table/Table";
import { documentsDataClient } from "../../../data/Data";
import { getData } from "../../../API/getData";
import { FaDownload } from "react-icons/fa6";
import { saveAs } from "file-saver";

const Documents = () => {
  const [tableData, setTableData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getData("manage/client/documents/");
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="secodary-bg p-1 border-0 me-2"
            onClick={() => saveAs(data.file_url, data.document_type_name)}
          >
            <FaDownload className="primary-text" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...documentsDataClient, actionColumn];

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb directory1="Documents" />
        <div>
          <Link to="/documents/add-documents" className="btn btn-dark">
            + Add Documents
          </Link>
        </div>
      </div>
      <div>
        <div className="filter-area"></div>
        <div className="table-area">
          <Table columns={finalColumn} dataSource={tableData} />
        </div>
      </div>
    </div>
  );
};

export default Documents;
