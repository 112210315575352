import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Table from "../../../component/table/Table";
import { getData } from "../../../API/getData";
import { activeStatusData, documentTypeColumn } from "../../../data/Data";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "../../../component/modal/Modal";
import deleteData from "../../../API/deleteData";
import Input from "../../../component/inputs/MaInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import postData from "../../../API/postData";
import putData from "../../../API/putData";

const DocumentType = () => {
  const [documentType, setDocumentType] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getData("manage/admin/document-type/");
      setDocumentType(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => (
      <div className="d-flex justify-content-center">
        <button
          className="primary-bg p-1 border-0 me-3"
          onClick={() => handleEditClick(data)}
        >
          <FiEdit2 className="text-white" />
        </button>
        <button
          className="bg-danger p-1 border-0"
          onClick={() => showDeleteModal(data)}
        >
          <AiOutlineDelete className="text-white" />
        </button>
      </div>
    ),
  };

  const finalColumn = [...documentTypeColumn, actionColumn];

  //   delete data section
  const [deleteDataModal, setDeleteDataModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const showDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteDataModal(true);
  };

  const cancelDelete = () => {
    setSelectedDeleteItem(null);
    setDeleteDataModal(false);
  };

  const deleteDataFunc = async () => {
    if (!selectedDeleteItem) {
      return;
    }

    const itemID = selectedDeleteItem.id;

    try {
      const response = await deleteData(
        `manage/admin/document-type/${itemID}/`
      );
      console.log(response);

      setDocumentType((prevData) =>
        prevData.filter((item) => item.id !== itemID)
      );

      setDeleteDataModal(false);
      setSelectedDeleteItem(null);
    } catch (error) {
      console.log(error);
    }
  };

  //   add data section
  const [addDocType, setAddDocType] = useState({
    name: "",
    is_active: null,
  });
  const handleInputChange = (name, value) => {
    setAddDocType((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [addModal, setAddModal] = useState(false);
  const addDocumentFunc = async (e) => {
    e.preventDefault();
    try {
      const response = await postData(
        "manage/admin/document-type/",
        addDocType
      );
      console.log(response);
      fetchData();
      setAddModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  //   edit data

  const [editModal, setEditModal] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const handleEditClick = (data) => {
    setSelectedEditItem(data.id);
    setAddDocType({
      name: data.name,
      is_active: data.is_active,
    });
    setEditModal(true);
  };
  const cancelEditModal = () => {
    setAddDocType({
      name: "",
      is_active: null,
    });
    setEditModal(false);
  };
  const editModalFunc = () => {
    putData(`manage/admin/document-type/${selectedEditItem}/`, addDocType).then(
      (res) => {
        console.log(res);
        fetchData();
        setEditModal(false);
        setSelectedEditItem(null);
      }
    );
  };

  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb directory1="Document Type" />
        <button className="btn btn-dark" onClick={() => setAddModal(true)}>
          + Add New
        </button>
      </div>
      <div>
        <Table columns={finalColumn} dataSource={documentType || ""} />
      </div>
      {/* Edit modal */}
      <Modal
        title="Edit Document Type"
        content={
          <div>
            <Input
              type="text"
              placeholder="Name"
              value={addDocType.name || ""}
              onChange={(value) => handleInputChange("name", value)}
            />
            <DropSelect
              label="Status"
              options={activeStatusData}
              value={addDocType.is_active}
              onChange={(value) => handleInputChange("is_active", value)}
            />
          </div>
        }
        visible={editModal}
        onCancel={cancelEditModal}
        onOk={editModalFunc}
      />

      {/* add modal */}
      <Modal
        title="Add Document Type"
        content={
          <div>
            <Input
              type="text"
              placeholder="Name"
              onChange={(value) => handleInputChange("name", value)}
            />
            <DropSelect
              label="Status"
              options={activeStatusData}
              onChange={(value) => handleInputChange("is_active", value)}
            />
          </div>
        }
        visible={addModal}
        onCancel={() => setAddModal(false)}
        onOk={addDocumentFunc}
      />

      {/* delete modal */}
      <Modal
        title="Delete Document Type"
        content={
          <div>
            <p>
              Are sure you want to delete{" "}
              {selectedDeleteItem ? (
                <span className="fw-bold">{selectedDeleteItem.name} ?</span>
              ) : (
                ""
              )}
            </p>
          </div>
        }
        visible={deleteDataModal}
        onCancel={cancelDelete}
        onOk={deleteDataFunc}
      />
    </div>
  );
};

export default DocumentType;
