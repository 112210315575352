import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../view/auth/Login";
import Dashboard from "../view/dashboard/Dashboard";
import MainLayout from "../view/main-layout/MainLayout";
import Register from "../view/auth/Register";
import ForgotPass from "../view/auth/ForgotPass";
import OtpPage from "../view/auth/AccountVerification";
import Profile from "../view/profile/Profile";
import ChangePass from "../view/auth/ChangePass";
import ForgotPassVerify from "../view/auth/ForgotPassVerification";
import { ProtectedRoutes } from "./ProtectedRoutes";
import NotFound from "../view/NotFound";
import ClientFromAdmin from "../view/admin-pages/client/ClientFromAdmin";
import ClientView from "../view/admin-pages/client/ClientView";
import ClientAdd from "../view/admin-pages/client/ClientAdd";
import DocumentType from "../view/admin-pages/document-type/DocumentType";
import ClientDocument from "../view/admin-pages/client-docs/ClientDocument";
import AddClientDocument from "../view/admin-pages/client-docs/AddClientDocument";
import { useProfile } from "../data/DataContext";
import ClientEdit from "../view/admin-pages/client/ClientEdit";
import Documents from "../view/client-pages/documents/Documents";
import AddDocuments from "../view/client-pages/documents/AddDocuments";
import ClientProfile from "../view/client-pages/profile/ClientProfile";
import SiteInfo from "../view/admin-pages/site-info/SiteInfo";

const AppRoutes = () => {
  const [forgotMail, setForgotMail] = useState("");
  const handleEmailChange = (email) => {
    setForgotMail(email);
  };
  const handleAccountVerify = (email) => {
    setForgotMail(email);
  };

  const { userData, setUser } = useProfile();
  // console.log("user data from app js", userData);
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/login" element={<Login setRole={setUser} />} />
      <Route
        path="/forgot-password"
        element={<ForgotPass onEmailChange={handleEmailChange} />}
      />
      <Route
        path="/register"
        element={<Register regEmail={handleAccountVerify} />}
      />
      <Route
        path="/account-verification"
        element={<OtpPage mail={forgotMail} />}
      />
      <Route
        path="/otp-verification"
        element={<ForgotPassVerify mail={forgotMail} />}
      />

      {/* --------------------protected routes------------- */}

      <Route path="/" element={<ProtectedRoutes Component={MainLayout} />}>
        <Route index element={<ProtectedRoutes Component={Dashboard} />} />
        <Route
          path="/profile"
          element={<ProtectedRoutes Component={Profile} />}
        />

        <Route
          path="/change-password"
          element={<ProtectedRoutes Component={ChangePass} />}
        />
        {userData === 0 && (
          <>
            <Route
              path="/document-type"
              element={<ProtectedRoutes Component={DocumentType} />}
            />
            <Route
              path="/client-documents"
              element={<ProtectedRoutes Component={ClientDocument} />}
            />
            <Route
              path="/client-documents/add-documents"
              element={<ProtectedRoutes Component={AddClientDocument} />}
            />
            <Route
              path="/Clients"
              element={<ProtectedRoutes Component={ClientFromAdmin} />}
            />
            <Route
              path="/Clients/add-client"
              element={<ProtectedRoutes Component={ClientAdd} />}
            />
            <Route
              path="/Clients/edit-client/:id"
              element={<ProtectedRoutes Component={ClientEdit} />}
            />
            <Route
              path="/clients/:id"
              element={<ProtectedRoutes Component={ClientView} />}
            />
            <Route
              path="/site-info"
              element={<ProtectedRoutes Component={SiteInfo} />}
            />
          </>
        )}

        {/* clients */}
        {userData === 1 && (
          <>
            <Route
              path="/documents"
              element={<ProtectedRoutes Component={Documents} />}
            />
            <Route
              path="/documents/add-documents"
              element={<ProtectedRoutes Component={AddDocuments} />}
            />
            <Route
              path="/client-profile"
              element={<ProtectedRoutes Component={ClientProfile} />}
            />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
