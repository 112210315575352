import React, { useEffect, useState } from "react";
import Input from "../../component/inputs/Input";
import { Link, useNavigate } from "react-router-dom";
import useInputChange from "../../utilities/useInputChange";
import axiosInstance from "../../API/AxiosInstance";

const Login = ({ setRole }) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setCompanyInfo(res.data.data.results);
    });
  }, []);

  const { inputData: loginData, handleInputChange } = useInputChange();

  const [loading, setLoading] = useState(false);
  const [errMsg, seterrMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/login/", loginData);
      setRole(response.data.data.role);
      const token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("login", true);
      let login = localStorage.getItem("login");
      if (login) {
        navigate("/");
      }
    } catch (error) {
      seterrMsg(
        error.response?.data?.errors?.email?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 w-100 position-relative">
      <div className="absolute-center p-2 col-12 col-sm-8 col-md-4 col-lg-3">
        <div className="w-100 p-3 primary-text fs-5 fw-bolder text-center auth-card-title">
          Login Form
        </div>
        <div className="my-4">
          <h2 className="text-center fw-bold">
            {companyInfo[0] ? companyInfo[0].site_name : "Company Name"}
          </h2>
        </div>
        {loading ? <p className="text-center">Loading...</p> : ""}
        {errMsg ? (
          <p className="text-center text-white bg-danger p-1">{errMsg}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={loginData.email || ""}
            onInputChange={handleInputChange}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={loginData.password || ""}
            onInputChange={handleInputChange}
          />

          <button type="submit" className="btn btn-dark w-100 mt-3">
            Login
          </button>
        </form>
        <div className="mt-2 d-flex justify-content-between">
          <Link to="/forgot-password">Forgot Password?</Link>
          <Link to="/register">Register</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
