import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/MaInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getData } from "../../../API/getData";
import { IoMdCloseCircleOutline } from "react-icons/io";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const ClientAdd = () => {
  const [clientData, setClientData] = useState({});
  const [basicInfos, setBasicInfos] = useState({});

  const handleInputChange = (name, value) => {
    setBasicInfos((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   required documents
  const [reqDocs, setReqDocs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const fetchReqDocs = async () => {
    try {
      const response = await getData("manage/admin/document-type/");
      setReqDocs(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, parseInt(selectedOption, 10)]);
    }
  };

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
  };
  // console.log(selectedOptions);

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setClientData({
      ...basicInfos,
      required_documents: selectedOptions,
    });
    setShouldSubmit(true);
  };

  const navigate = useNavigate();
  const postClient = useCallback(async () => {
    try {
      const response = await postData("manage/admin/client/", clientData);
      console.log(response);
      navigate("/clients");
    } catch (error) {
      console.log(error);
    }
  }, [clientData, navigate]);

  useEffect(() => {
    fetchReqDocs();
    if (shouldSubmit) {
      postClient();
      setShouldSubmit(false);
    }
  }, [shouldSubmit, selectedOptions, basicInfos, postClient]);
  // console.log("submitted data", clientData);
  return (
    <div>
      <div className="mb-5">
        <Breadcrumb
          directory1="Clients"
          path="/clients"
          directory2="Add Client"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <h4 className="fw-bold">Client Information</h4>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="text"
              placeholder="First Name"
              onChange={(value) => handleInputChange("first_name", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="text"
              placeholder="Last Name"
              onChange={(value) => handleInputChange("last_name", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="email"
              placeholder="Email"
              onChange={(value) => handleInputChange("email", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="password"
              placeholder="Password"
              onChange={(value) => handleInputChange("password", value)}
              required={true}
            />
          </div>
          <div className="col-12">
            <Input
              type="text"
              placeholder="Social Security Number"
              onChange={(value) => handleInputChange("ssn", value)}
              required={true}
            />
          </div>
        </div>
        <div className="mb-4">
          <h4 className="fw-bold mb-3">Required Documents</h4>
          <div>
            <DropSelect
              label="Required Documents"
              options={[
                { value: null, title: "Select an option", disabled: true },
                ...reqDocs.map((item) => ({
                  value: item.id,
                  title: item.name,
                })),
              ]}
              onChange={handleOptionSelect}
            />
            <div className="mt-4 d-flex flex-wrap">
              {selectedOptions.map((selectedOption) => {
                const selectedDoc = reqDocs.find(
                  (doc) => doc.id === selectedOption
                );

                return (
                  <div
                    key={selectedOption}
                    className="fade-bg p-2 position-relative me-3"
                  >
                    <p className="mb-0">
                      {selectedDoc ? selectedDoc.name : "Unknown"}
                    </p>
                    <button
                      className="position-absolute bg-transparent border-0 req-doc-close"
                      onClick={() => handleRemoveOption(selectedOption)}
                    >
                      <IoMdCloseCircleOutline className="fs-5" />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="mb-5">
          <button className="btn btn-dark w-100 fs-4 fw-600" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientAdd;
