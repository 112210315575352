import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getData } from "../../../API/getData";
import {
  approvalStatusData,
  getFileType,
  statusTypeData,
} from "../../../data/Data";
import Input from "../../../component/inputs/MaInput";
import postData from "../../../API/postData";
import { postDocumentData } from "../../../API/postDocumentData";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../API/AxiosInstance";

const AddClientDocument = () => {
  const [clientData, setClientData] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const fetchDocumentType = async () => {
    try {
      const response = await getData("manage/admin/document-type/");
      setDocumentType(response);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await getData("manage/admin/client/");
      setClientData(response);
    } catch (error) {
      console.log(error);
    }
  };
  const [docData, setDocData] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const token = `Token ${localStorage.getItem("token")}`;
  const fetchClient = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `manage/admin/client/${docData.client}/`,
        {
          headers: { Authorization: token },
        }
      );
      setSelectedClient(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [docData, token]);

  // console.log(selectedClient);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const handleInputChange = (name, value) => {
    if (
      name === "file" ||
      name === "client" ||
      name === "approval_status" ||
      name === "document_type" ||
      name === "status"
    ) {
      value = value ? parseInt(value, 10) : 0;
    }
    setDocData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [fileInput, setFileInput] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document", fileInput);
    const docType = getFileType(fileInput.name);
    formData.append("doc_type", docType);

    try {
      const docUpload = await postDocumentData(
        "auth/documents/upload/",
        formData
      );
      setDocData((prev) => ({
        ...prev,
        file: docUpload.data.id,
      }));
      setShouldSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
    fetchDocumentType();
    if (docData.client) {
      fetchClient();
    }
    if (shouldSubmit) {
      postData("manage/admin/client-documents/", docData).then((res) => {
        console.log(res);
        setShouldSubmit(false);
        navigate("/client-documents");
      });
    }
  }, [docData, shouldSubmit, navigate, fetchClient]);

  return (
    <div>
      <div className="mb-5">
        <Breadcrumb
          directory1="Client Documents"
          path="/client-documents"
          directory2="Add Documents"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label fw-bold">File</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFileInput(e.target.files[0])}
              />
            </div>
          </div>
          <div className="col-md-6">
            <DropSelect
              label="Client"
              options={[
                {
                  value: null,
                  title: "Select an option",
                  disabled: true,
                },
                ...clientData.map((item) => ({
                  value: item.id,
                  title: item.first_name,
                })),
              ]}
              onChange={(value) => handleInputChange("client", value)}
            />
          </div>
          <div className="col-md-6">
            <DropSelect
              label="Approval Status"
              options={approvalStatusData}
              onChange={(value) => handleInputChange("approval_status", value)}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="date"
              placeholder="Expiry Date"
              onChange={(value) => handleInputChange("expiry_date", value)}
            />
          </div>
          <div className="col-md-6">
            <DropSelect
              label="Status"
              options={statusTypeData}
              onChange={(value) => handleInputChange("status", value)}
            />
          </div>
          <div className="col-md-6">
            <DropSelect
              label="Document Type"
              options={[
                {
                  value: null,
                  title: "Select an option",
                  disabled: true,
                },
                ...(selectedClient.required_documents
                  ? documentType
                      .filter((item) =>
                        selectedClient.required_documents.includes(item.id)
                      )
                      .map((item) => ({
                        value: item.id,
                        title: item.name,
                      }))
                  : documentType.map((item) => ({
                      value: item.id,
                      title: item.name,
                    }))),
              ]}
              onChange={(value) => handleInputChange("document_type", value)}
            />
          </div>
          <button className="btn btn-dark w-100 mt-3" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddClientDocument;
