import React, { useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Input from "../../component/inputs/MaInput";
// import postData from "../../API/postData";
import axiosInstance from "../../API/AxiosInstance";

const ChangePass = () => {
  const [changePass, setChangePass] = useState({});
  const [msg, setMsg] = useState("");
  const handleInputChange = (name, value) => {
    setChangePass({
      ...changePass,
      [name]: value,
    });
  };
  const token = `Token ${localStorage.getItem("token")}`;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(
        "auth/change/password/",
        changePass,
        {
          headers: { Authorization: token },
        }
      );

      setMsg(response.data.message);
    } catch (err) {
      setMsg(
        err.response?.data?.errors?.non_field_errors?.[0] ||
          err.response?.data?.errors?.confirm_password?.[0] ||
          err.response?.data?.message
      );
      console.log(err.response);
    }
  };
  // console.log(msg);
  return (
    <div>
      <div className="mb-5">
        <Breadcrumb directory1="Change Password" />
      </div>
      <div>
        <form
          className="absolute-center p-2 col-12 col-sm-8 col-md-6 col-lg-4"
          onSubmit={handleSubmit}
        >
          {msg ? <p className="text-center p-1 fade-bg mb-3">{msg}</p> : ""}
          <Input
            type="password"
            placeholder="Old Password"
            onChange={(value) => handleInputChange("old_password", value)}
          />
          <Input
            type="password"
            placeholder="New Password"
            onChange={(value) => handleInputChange("password", value)}
          />
          <Input
            type="password"
            placeholder="New Password (again)"
            onChange={(value) => handleInputChange("confirm_password", value)}
          />
          <button type="submit" className="btn btn-dark w-100 mt-3">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePass;
