import React, { useState, useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { MdOutlineChangeCircle, MdOutlineLogout } from "react-icons/md";
import DropMenu from "../../component/dropdowns/DropMenu";
import { Link } from "react-router-dom";
import axiosInstance from "../../API/AxiosInstance";
import user from "../../assets/image/user.jpg";

const Navbar = (props) => {
  const [profileData, setProfileData] = useState([]);
  const token = `Token ${localStorage.getItem("token")}`;

  useEffect(() => {
    let login = localStorage.getItem("login");
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("auth/profile/", {
          headers: { Authorization: token },
        });
        setProfileData(response.data.data);
      } catch (error) {
        console.error("Error fetching profile data", error);
      }
    };
    if (login) {
      fetchData();
    }
  }, [token]);

  const [toggle, setToggle] = useState(false);

  // function to toggle navbar from hide to display
  const handleSidebarToggle = (e) => {
    e.stopPropagation();
    setToggle(!toggle);
    props.onToggle();
  };

  const handleSignout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.setItem("activeMenuId", 1);
  };

  return (
    <nav className="navbar-container border-bottom w-100 px-4 d-flex align-items-center justify-content-between bg-white shadow-sm">
      <div className="menu-and-search-section d-flex align-items-center">
        <div className="rounded-0">
          <BiMenu
            style={{ backgroundColor: "#F5F7FA" }}
            className="fs-2 p-1 c-pointer text-dark"
            onClick={handleSidebarToggle}
          />
        </div>
      </div>
      <div className="profile-and-notification-section d-flex align-items-center">
        <div className="position-relative">
          <div className="ms-4 d-flex align-items-center b-radius">
            <DropMenu
              buttonLabel={
                <div className="d-flex align-items-center">
                  <img
                    style={{ maxHeight: "40px" }}
                    className="img-thumbnail rounded-0 me-2"
                    src={profileData.image_url || user}
                    alt={profileData.first_name || ""}
                  />
                  <div className="d-flex flex-column align-items-start">
                    <span>{profileData.first_name}</span>
                    <span className="fw-normal">
                      {profileData.role === 0 ? "admin" : "user"}
                    </span>
                  </div>
                </div>
              }
            >
              <div
                style={{
                  zIndex: "1",
                  top: "45px",
                  minWidth: "200px",
                  right: "-35px",
                }}
                className="d-flex flex-column position-absolute p-2 border shadow-sm bg-white drop-menu-profile"
              >
                <Link
                  to="/profile"
                  className="mb-2 d-flex align-items-center text-default"
                >
                  <FiUser className="fs-15" />
                  <span className="ms-2 fs-15 ">Profile</span>
                </Link>
                <Link
                  to="/change-password"
                  className="mb-2 d-flex align-items-center text-default"
                >
                  <MdOutlineChangeCircle className="fs-15" />
                  <span className="ms-2 fs-15">Change Password</span>
                </Link>
                <Link
                  to="/login"
                  onClick={handleSignout}
                  className="mb-2 d-flex align-items-center text-default"
                >
                  <MdOutlineLogout className="fs-15" />
                  <span className="ms-2 fs-15">Signout</span>
                </Link>
              </div>
            </DropMenu>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
