import "./App.css";
import "./assets/style/auth.css";
import "./assets/style/components.css";
import AppRoutes from "./Routes/AppRoutes";

function App() {
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
