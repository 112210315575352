import React, { useEffect, useState } from "react";
import "../../assets/style/views/dashboard.css";
import DashCardSm from "./DashCardSm";
import { GETData } from "../../API/GETDataV2";
import { useProfile } from "../../data/DataContext";

const Dashboard = () => {
  const [dashInfo, setDashInfo] = useState([]);
  const [dashInfoUser, setDashInfoUser] = useState([]);

  const { userData } = useProfile();
  useEffect(() => {
    userData === 0
      ? GETData("utility/admin/dashboard-info/").then((res) => {
          setDashInfo(res);
        })
      : GETData("utility/mobile/user-dashboard-info/").then((res) => {
          setDashInfoUser(res);
        });
  }, [userData]);

  return (
    <div>
      {userData === 0 ? (
        <div className="row">
          {Object.keys(dashInfo).map((key) => (
            <DashCardSm
              key={key}
              title={key.replace("_", " ").toUpperCase()}
              value={dashInfo[key]}
            />
          ))}
        </div>
      ) : (
        <div className="row">
          {Object.keys(dashInfoUser).map((key) => (
            <DashCardSm
              key={key}
              title={key.replace("_", " ").toUpperCase()}
              value={dashInfoUser[key]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
