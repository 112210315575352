import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Input from "../../../component/inputs/MaInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getData } from "../../../API/getData";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../API/AxiosInstance";
// import putData from "../../../API/putData";
import patchData from "../../../API/patchData";

const ClientEdit = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState({});

  //   required documents
  const [reqDocs, setReqDocs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // console.log("selected options", selectedOptions);
  const fetchReqDocs = async () => {
    try {
      const response = await getData("manage/admin/document-type/");
      setReqDocs(response);
    } catch (error) {
      console.log(error);
    }
  };

  const token = `Token ${localStorage.getItem("token")}`;
  const fetchClientData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`manage/admin/client/${id}/`, {
        headers: { Authorization: token },
      });
      setClientData(response.data.data);
      setSelectedOptions(response.data.data.required_documents);
    } catch (err) {
      console.log(err);
    }
  }, [token, id]);
  // console.log("client data", clientData);

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, parseInt(selectedOption, 10)]);
    }
  };

  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
  };

  const handleInputChange = (name, value) => {
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataToUpdate = {
        first_name: clientData.first_name,
        last_name: clientData.last_name,
        email: clientData.email,
        ssn: clientData.ssn,
        required_documents: selectedOptions,
      };

      if (clientData.password) {
        dataToUpdate.password = clientData.password;
      }
      const response = await patchData(
        `manage/admin/client/${id}/`,
        dataToUpdate
      );
      console.log(response);
      navigate("/clients");
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchReqDocs();
    fetchClientData();
  }, [fetchClientData]);

  return (
    <div>
      <div className="mb-5">
        <Breadcrumb
          directory1="Clients"
          path="/clients"
          directory2="Edit Client"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <h4 className="fw-bold">Client Information</h4>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="text"
              placeholder="First Name"
              value={clientData.first_name || ""}
              onChange={(value) => handleInputChange("first_name", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="text"
              placeholder="Last Name"
              value={clientData.last_name || ""}
              onChange={(value) => handleInputChange("last_name", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="email"
              placeholder="Email"
              value={clientData.email || ""}
              onChange={(value) => handleInputChange("email", value)}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <Input
              type="password"
              placeholder="Password"
              value={clientData.password || ""}
              onChange={(value) => handleInputChange("password", value)}
              // required={true}
            />
          </div>
          <div className="col-12">
            <Input
              type="text"
              placeholder="Social Security Number"
              value={clientData.ssn || ""}
              onChange={(value) => handleInputChange("ssn", value)}
              required={true}
            />
          </div>
        </div>
        <div className="mb-4">
          <h4 className="fw-bold mb-3">Required Documents</h4>
          <div>
            <DropSelect
              label="Required Documents"
              //   value={clientData.required_documents}
              options={[
                { value: null, title: "Select an option", disabled: true },
                ...reqDocs.map((item) => ({
                  value: item.id,
                  title: item.name,
                })),
              ]}
              onChange={handleOptionSelect}
            />
            <div className="mt-4 d-flex flex-wrap">
              {selectedOptions &&
                selectedOptions.map((selectedOption) => {
                  const selectedDoc = reqDocs.find(
                    (doc) => doc.id === selectedOption
                  );

                  return (
                    <div
                      key={selectedOption}
                      className="fade-bg p-2 position-relative me-3"
                    >
                      <p className="mb-0">
                        {selectedDoc ? selectedDoc.name : "Unknown"}
                      </p>
                      <button
                        className="position-absolute bg-transparent border-0 req-doc-close"
                        onClick={() => handleRemoveOption(selectedOption)}
                      >
                        <IoMdCloseCircleOutline className="fs-5" />
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="mb-5">
          <button className="btn btn-dark w-100 fs-4 fw-600" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientEdit;
