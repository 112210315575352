import React, { useEffect, useState, useCallback } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { getFileType } from "../../../data/Data";
import { postDocumentData } from "../../../API/postDocumentData";
import axiosInstance from "../../../API/AxiosInstance";
import postData from "../../../API/postData";
import { useNavigate } from "react-router-dom";

const AddDocuments = () => {
  const [docuType, setDocuType] = useState([]);
  const token = `Token ${localStorage.getItem("token")}`;
  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        "manage/client/profile/client-document-type/",
        {
          headers: { Authorization: token },
        }
      );
      setDocuType(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [token]);
  // console.log(docuType);
  const [document, setDocument] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [fileInput, setFileInput] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document", fileInput);
    const docType = getFileType(fileInput.name);
    formData.append("doc_type", docType);

    try {
      const docUpload = await postDocumentData(
        "auth/documents/upload/",
        formData
      );
      setDocument((prev) => ({
        ...prev,
        file: docUpload.data.id,
      }));
      setShouldSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(docuType);
  const handleInputChange = (name, value) => {
    value = value ? parseInt(value, 10) : 0;

    setDocument((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
    if (shouldSubmit) {
      postData("manage/client/documents/", document)
        .then((res) => {
          console.log(res);
          setShouldSubmit(false);
          navigate("/documents");
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [shouldSubmit, document, fetchData, navigate]);
  return (
    <div>
      <div className="mb-5">
        <Breadcrumb
          directory1="Documents"
          path="/documents"
          directory2="Add Documents"
        />
      </div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label fw-bold">File</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFileInput(e.target.files[0])}
              />
            </div>
          </div>
          <div className="col-md-6">
            <DropSelect
              label="Document Type"
              options={
                docuType
                  ? [
                      {
                        value: null,
                        title: "Select an option",
                        disabled: true,
                      },
                      ...docuType.map((item) => ({
                        value: item.id,
                        title: item.name,
                      })),
                    ]
                  : []
              }
              onChange={(value) => handleInputChange("document_type", value)}
            />
          </div>
        </div>
        <div className="col-12">
          <button
            className="btn btn-dark w-100 mt-3"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddDocuments;
