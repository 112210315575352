import { useState } from "react";

const useInputChange = () => {
  const [inputData, setInputData] = useState({});

  const handleInputChange = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return { inputData, handleInputChange };
};

export default useInputChange;
