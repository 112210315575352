import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Table from "../../../component/table/Table";
import { adminClientShortColumn } from "../../../data/Data";
import { getData } from "../../../API/getData";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import Modal from "../../../component/modal/Modal";
import deleteData from "../../../API/deleteData";
import { Link, useNavigate } from "react-router-dom";
import SearchInput from "../../../component/inputs/SearchInput";

const ClientFromAdmin = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await getData("manage/admin/client/");
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => (
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="primary-bg p-1 border-0"
          onClick={() => handleEditClick(data.id)}
        >
          <FiEdit2 className="text-white" />
        </button>
        <button
          className="primary-bg p-1 border-0"
          onClick={() => handleViewClick(data.id)}
        >
          <FaEye className="text-white" />
        </button>
        <button
          className="bg-danger p-1 border-0"
          onClick={() => showDeleteModal(data)}
        >
          <AiOutlineDelete className="text-white" />
        </button>
      </div>
    ),
  };
  const finalDataColumn = [...adminClientShortColumn, actionColumn];
  //   view data
  const handleViewClick = (id) => {
    navigate(`/clients/${id}`);
  };

  // edit function
  const handleEditClick = (item) => {
    navigate(`/clients/edit-client/${item}`);
  };

  //   delete data
  const [deleteDataModal, setDeleteDataModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const showDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteDataModal(true);
  };

  const cancelDelete = () => {
    setSelectedDeleteItem(null);
    setDeleteDataModal(false);
  };

  const deleteDataFunc = async () => {
    if (!selectedDeleteItem) {
      return;
    }
    const itemID = selectedDeleteItem.id;
    try {
      const response = await deleteData(`manage/admin/client/${itemID}/`);
      console.log(response);
      setTableData((prevData) => prevData.filter((item) => item.id !== itemID));
      setDeleteDataModal(false);
      setSelectedDeleteItem(null);
    } catch (error) {}
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const filteredData = tableData.filter((data) => {
    return (
      data.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.ssn.toString().includes(searchQuery)
    );
  });
  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb directory1="Clients" />
        <div>
          <Link to="/clients/add-client" className="btn btn-dark">
            + Add Client
          </Link>
        </div>
      </div>
      <div>
        <div className="filter-area col-lg-5 col-xl-4 col-xxl-3 mb-3">
          <SearchInput
            onChange={handleSearchInputChange}
            placeHolder="First Name / SSN"
          />
        </div>
        <div className="table-area">
          <Table columns={finalDataColumn} dataSource={filteredData} />
        </div>
      </div>
      {/* delete data modal */}
      <Modal
        title="Delete Client"
        content={
          <div>
            <p>
              Are sure you want to delete{" "}
              {selectedDeleteItem ? (
                <span className="fs-5 fw-bold">
                  {selectedDeleteItem.first_name} ?
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        }
        visible={deleteDataModal}
        onCancel={cancelDelete}
        onOk={deleteDataFunc}
      />
    </div>
  );
};

export default ClientFromAdmin;
