import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import Table from "../../../component/table/Table";
import { approvalStatusData, clientDocsColumn } from "../../../data/Data";
import { getData } from "../../../API/getData";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "../../../component/modal/Modal";
import deleteData from "../../../API/deleteData";
import { FiEdit2 } from "react-icons/fi";
import DropSelect from "../../../component/dropdowns/DropSelect";
import Input from "../../../component/inputs/MaInput";
import postData from "../../../API/postData";
import { FaDownload } from "react-icons/fa6";
import { saveAs } from "file-saver";
import SearchInput from "../../../component/inputs/SearchInput";

const ClientDocument = () => {
  const [tableData, setTableData] = useState([]);

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      // console.log(data);
      return (
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="secodary-bg p-1 border-0 me-2"
            onClick={() => saveAs(data.file_url, data.document_type_name)}
          >
            <FaDownload className="primary-text" />
          </button>
          <button
            className="primary-bg p-1 border-0"
            onClick={() => handleEditClick(data)}
          >
            <FiEdit2 className="text-white" />
          </button>

          <button
            className="bg-danger p-1 border-0 ms-2"
            onClick={() => showDeleteModal(data)}
          >
            <AiOutlineDelete className="text-white" />
          </button>
        </div>
      );
    },
  };
  const finalColumn = [...clientDocsColumn, actionColumn];

  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [deleteDataModal, setDeleteDataModal] = useState(false);

  const showDeleteModal = (item) => {
    setSelectedDeleteItem(item);
    setDeleteDataModal(true);
  };

  const cancelDelete = () => {
    setSelectedDeleteItem(null);
    setDeleteDataModal(false);
  };

  const deleteDataFunc = async () => {
    if (!selectedDeleteItem) {
      return;
    }

    const itemID = selectedDeleteItem.id;

    try {
      const response = await deleteData(
        `manage/admin/client-documents/${itemID}/`
      );
      console.log(response);

      setTableData((prevData) => prevData.filter((item) => item.id !== itemID));
      setDeleteDataModal(false);
      setSelectedDeleteItem(null);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getData("manage/admin/client-documents/");
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditInputChange = (name, value) => {
    if (name === "approval_status") {
      value = value ? parseInt(value, 10) : 0;
    }
    setSelectedEditItem({
      ...selectedEditItem,
      [name]: value,
    });
  };

  // edit status section
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const [editDataModal, setEditDataModal] = useState(false);

  const handleEditClick = (item) => {
    setSelectedEditItem(item);
    setEditDataModal(true);
  };
  const cancelEdit = () => {
    setSelectedEditItem(null);
    setEditDataModal(false);
  };

  const editStatusFunc = async () => {
    if (!selectedEditItem) {
      return;
    }
    const itemID = selectedEditItem.id;

    try {
      const response = await postData(
        `manage/admin/client-documents/${itemID}/change_document_status/`,
        {
          approval_status: selectedEditItem.approval_status,
          reject_reason: selectedEditItem.reject_reason,
        }
      );
      console.log(response);
      setEditDataModal(false);
      setSelectedEditItem(null);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const filteredData = tableData.filter((data) => {
    return (
      data.client_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.document_type_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb directory1="Client Documents" />
        <div>
          <Link to="/client-documents/add-documents" className="btn btn-dark">
            + Add Documents
          </Link>
        </div>
      </div>
      <div>
        <div className="filter-area col-lg-5 col-xl-4 col-xxl-3 mb-3">
          <SearchInput
            onChange={handleSearchInputChange}
            placeHolder="Client Name / Document Type"
          />
        </div>
        <div className="table-area">
          <Table columns={finalColumn} dataSource={filteredData} />
        </div>
      </div>
      {/* delete modal */}
      <Modal
        title="Delete Payment"
        content={
          <div>
            <p>
              Are sure you want to delete the file-
              {selectedDeleteItem ? (
                <span className="fs-5 fw-bold">
                  {selectedDeleteItem.file} ?
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        }
        visible={deleteDataModal}
        onCancel={cancelDelete}
        onOk={deleteDataFunc}
      />

      {/* edit status modal */}
      <Modal
        title="Change Status"
        content={
          <div>
            <DropSelect
              label="Approval Status"
              options={approvalStatusData}
              value={selectedEditItem ? selectedEditItem.approval_status : ""}
              onChange={(value) =>
                handleEditInputChange("approval_status", value)
              }
            />
            {selectedEditItem ? (
              selectedEditItem.approval_status === 3 ? (
                <Input
                  type="text"
                  placeholder="Reject Reason"
                  value={
                    selectedEditItem ? selectedEditItem.reject_reason || "" : ""
                  }
                  onChange={(value) =>
                    handleEditInputChange("reject_reason", value)
                  }
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        }
        visible={editDataModal}
        onCancel={cancelEdit}
        onOk={editStatusFunc}
      />
    </div>
  );
};

export default ClientDocument;
