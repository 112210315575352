import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdManageAccounts,
  MdOutlineDocumentScanner,
} from "react-icons/md";
import { useProfile } from "../../data/DataContext";
import { CgWebsite } from "react-icons/cg";
import axiosInstance from "../../API/AxiosInstance";

// import Logo from "../../assets/Logo.png";

const Sidebar = ({ toggle, toggleSidebar }) => {
  const [siteInfoData, setSiteInfoData] = useState({});

  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setSiteInfoData(res.data.data.results);
    });
  }, []);

  const { userData } = useProfile();
  // console.log(userData);
  const location = useLocation();

  const [menuItems] = useState(
    userData === 0
      ? [
          {
            id: 1,
            title: "Dashboard",
            icon: AiOutlineHome,
            path: "/",
            subMenus: [],
          },
          {
            id: 2,
            title: "Manage Client",
            icon: MdManageAccounts,
            path: "/clients",
            subMenus: [],
          },
          {
            id: 3,
            title: "Manage Documents",
            icon: MdOutlineDocumentScanner,
            subMenus: [
              { label: "Document Type", path: "/document-type" },
              { label: "Client Documents", path: "/client-documents" },
            ],
          },
          {
            id: 4,
            title: "Site Info",
            icon: CgWebsite,
            path: "/site-info",
            subMenus: [],
          },
        ]
      : [
          {
            id: 1,
            title: "Dashboard",
            icon: AiOutlineHome,
            path: "/",
            subMenus: [],
          },
          {
            id: 2,
            title: "Documents",
            icon: MdOutlineDocumentScanner,
            path: "/documents",
            subMenus: [],
          },
          {
            id: 3,
            title: "Profile",
            icon: MdManageAccounts,
            path: "/client-profile",
            subMenus: [],
          },
        ]
  );

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);

  useEffect(() => {
    const activeMenuId = localStorage.getItem("activeMenuId");
    const activeSubmenuId = localStorage.getItem("activeSubmenuId");

    setActiveIndex(activeMenuId ? parseInt(activeMenuId) : 1);
    setActiveSubmenuIndex(activeSubmenuId ? parseInt(activeSubmenuId) : null);
  }, []);

  const toggleSubMenu = (id) => {
    setActiveIndex(activeIndex === id ? null : id);
    setActiveSubmenuIndex(null);

    localStorage.setItem("activeMenuId", activeIndex === id ? null : id);
    localStorage.removeItem("activeSubmenuId");
  };

  const toggleSubmenuItem = (submenuIndex) => {
    setActiveSubmenuIndex(
      activeSubmenuIndex === submenuIndex ? null : submenuIndex
    );

    localStorage.setItem(
      "activeSubmenuId",
      activeSubmenuIndex === submenuIndex ? null : submenuIndex
    );
  };

  const isSubmenuActive = (submenuPath) => {
    return location.pathname.startsWith(submenuPath);
  };

  return (
    <nav
      className={`sidebar border-end primary-bg ${
        toggle ? "sidebar-hide" : "sidebar-show"
      }`}
    >
      <div className="logo-wrapper p-2 border border-end-0 shadow-sm bg-white">
        <img
          className="logo-img"
          src={siteInfoData[0] ? siteInfoData[0].logo_url : ""}
          alt={siteInfoData[0] ? siteInfoData.site_name : "Company Name"}
        />
        <h5 className="fw-bolder text-center ms-2">
          {siteInfoData[0] ? siteInfoData[0].site_name : "Company Name"}
        </h5>
      </div>
      <div className="menu-items-wrapper">
        <ul className="menu-ul">
          {menuItems.map((menuItem) => (
            <li key={menuItem.id}>
              <Link
                to={menuItem.path}
                className={`menu-item px-4 py-3 ${
                  activeIndex === menuItem.id ? "menu-active" : ""
                }`}
                onClick={() => {
                  toggleSubMenu(menuItem.id);
                  if (menuItem.subMenus.length === 0) {
                    toggleSidebar();
                  }
                }}
              >
                {React.createElement(menuItem.icon)}
                <div className="menu-title">{menuItem.title}</div>
                {menuItem.subMenus.length > 0 && (
                  <>
                    {activeIndex === menuItem.id ? (
                      <MdKeyboardArrowUp className="submenu-icon" />
                    ) : (
                      <MdKeyboardArrowDown className="submenu-icon" />
                    )}
                  </>
                )}
              </Link>
              {menuItem.subMenus.length > 0 && (
                <ul
                  className={`submenu-ul pb-3 ${
                    activeIndex === menuItem.id ? "visible" : ""
                  }`}
                >
                  {menuItem.subMenus.map((submenu, submenuIndex) => (
                    <li
                      className="submenu-li text-dark py-2"
                      key={submenuIndex}
                    >
                      <Link
                        to={`${submenu.path}`}
                        className={`submenu-item position-relative text-decoration-none ${
                          isSubmenuActive(submenu.path) ? "submenu-active" : ""
                        }`}
                        onClick={() => {
                          toggleSubmenuItem(submenuIndex);
                          toggleSidebar();
                        }}
                      >
                        {submenu.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
