import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import Table from "../../../component/table/Table";
import {
  clientProfileColumn,
  genderData,
  getFileType,
} from "../../../data/Data";
import { getData } from "../../../API/getData";
import { FiEdit2 } from "react-icons/fi";
import Modal from "../../../component/modal/Modal";
import Input from "../../../component/inputs/MaInput";
import DropSelect from "../../../component/dropdowns/DropSelect";
import { postDocumentData } from "../../../API/postDocumentData";
import putData from "../../../API/putData";

const ClientProfile = () => {
  const [tableData, setTableData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getData("manage/client/profile/");
      setTableData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => (
      <div className="d-flex align-items-center">
        <button
          className="primary-bg p-1 border-0"
          onClick={() => handleEditClick(data)}
        >
          <FiEdit2 className="text-white" />
        </button>
      </div>
    ),
  };
  const finalColumn = [...clientProfileColumn, actionColumn];

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [editDataModal, setEditDataModal] = useState(false);
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const handleInputChange = (name, value) => {
    setSelectedEditItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (data) => {
    setSelectedEditItem(data);
    setEditDataModal(true);
  };
  const [fileInput, setFileInput] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      const docType = getFileType(fileInput.name);
      formData.append("doc_type", docType);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setSelectedEditItem((prev) => ({
          ...prev,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };

  useEffect(() => {
    fetchData();
    if (shouldSubmit) {
      putData(`manage/client/profile/${selectedEditItem.id}/`, {
        first_name: selectedEditItem.first_name,
        last_name: selectedEditItem.last_name,
        image: selectedEditItem.image,
        dob: selectedEditItem.dob,
        gender: selectedEditItem.gender,
      })
        .then((res) => {
          console.log(res);
          setShouldSubmit(false);
          setEditDataModal(false);
          setSelectedEditItem(null);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [selectedEditItem, shouldSubmit]);

  return (
    <div className="client-profile">
      <div className="mb-5">
        <Breadcrumb directory1="Client Profile" />
      </div>
      <div>
        <div>
          <div className="filter-area"></div>
          <div className="table-area">
            <Table columns={finalColumn} dataSource={tableData} />
          </div>
        </div>
      </div>
      <Modal
        title="Edit Client Profile"
        content={
          <div>
            <Input
              type="text"
              value={selectedEditItem ? selectedEditItem.first_name : ""}
              placeholder="First Name"
              onChange={(value) => handleInputChange("first_name", value)}
            />
            <Input
              type="text"
              value={selectedEditItem ? selectedEditItem.last_name : ""}
              placeholder="Last Name"
              onChange={(value) => handleInputChange("last_name", value)}
            />
            <img
              className="img-fluid"
              style={{ maxHeight: "70px" }}
              src={selectedEditItem ? selectedEditItem.image_url : ""}
              alt="profile"
            />
            <div className="mb-3">
              <label className="form-label fw-bold">Image</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFileInput(e.target.files[0])}
              />
            </div>
            <Input
              type="date"
              value={selectedEditItem ? selectedEditItem.dob : ""}
              placeholder="DOB"
              onChange={(value) => handleInputChange("dob", value)}
            />
            <DropSelect
              label="Gender"
              options={genderData}
              value={selectedEditItem ? selectedEditItem.gender : ""}
              onChange={(value) => handleInputChange("gender", value)}
            />
          </div>
        }
        visible={editDataModal}
        onCancel={() => setEditDataModal(false)}
        onOk={handleSubmit}
      />
    </div>
  );
};

export default ClientProfile;
