import React, { useState, useEffect } from "react";
import Input from "../../component/inputs/Input";
import useInputChange from "../../utilities/useInputChange";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../API/AxiosInstance";

const ForgotPass = ({ onEmailChange }) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setCompanyInfo(res.data.data.results);
    });
  }, []);
  const { inputData: forgotData, handleInputChange } = useInputChange();

  const [loading, setLoading] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "auth/forget/password/",
        forgotData
      );
      onEmailChange(forgotData.email);
      if (response.status === 200) {
        navigate("/otp-verification");
      }
    } catch (error) {
      seterrMsg(
        error.response?.data?.errors?.email?.[0] ||
          error.response?.data?.message
      );
      handleInputChange("email", "");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="vh-100 w-100 position-relative">
      <div className="absolute-center p-2 col-12 col-sm-8 col-md-4 col-lg-3">
        <div className="w-100 p-3 primary-text fs-5 fw-bolder text-center auth-card-title">
          Forgot Password Form
        </div>
        <div className="my-4">
          <h2 className="text-center fw-bold">
            {companyInfo[0] ? companyInfo[0].site_name : "Company Name"}
          </h2>
        </div>
        <div>
          {loading ? <p className="text-center">Loading...</p> : ""}
          {errMsg ? (
            <p className="text-center text-white bg-danger p-1">{errMsg}</p>
          ) : (
            ""
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            name="email"
            value={forgotData.email || ""}
            onInputChange={handleInputChange}
          />

          <button type="submit" className="btn btn-dark w-100 mt-3">
            Submit
          </button>
        </form>
        <div className="mt-2 d-flex justify-content-between"></div>
      </div>
    </div>
  );
};

export default ForgotPass;
