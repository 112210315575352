import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ directory1, directory2, path }) => {
  return (
    <nav>
      <h4 className="fw-bold">{directory2 ? directory2 : directory1}</h4>
      <ol className="breadcrumb mb-1">
        <li className="breadcrumb-item">
          <Link
            className="text-decoration-none text-muted"
            to="/"
            onClick={() => {
              localStorage.setItem("activeMenuId", 1);
            }}
          >
            Dashboard
          </Link>
        </li>
        <li className={`breadcrumb-item ${directory2 ? "active" : ""}`}>
          <Link
            to={directory2 ? `${path}` : "#"}
            className="text-decoration-none"
          >
            {directory1}
          </Link>
        </li>
        {directory2 ? <li className="breadcrumb-item">{directory2}</li> : null}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
