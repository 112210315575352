import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb/Breadcrumb";
import Input from "../../component/inputs/MaInput";
import axiosInstance from "../../API/AxiosInstance";
import { postDocumentData } from "../../API/postDocumentData";
import postData from "../../API/postData";

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileInput, setFileInput] = useState();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const token = `Token ${localStorage.getItem("token")}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("auth/profile/", {
          headers: { Authorization: token },
        });
        setProfileData(response.data.data);
      } catch (error) {
        console.error("Error fetching profile data", error);
      }
    };

    if (shouldSubmit) {
      postData("auth/profile/", {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        image: profileData.image,
      }).then((res) => {
        console.log(res);
        setShouldSubmit(false);
      });
    }
    fetchData();
  }, [token, shouldSubmit]);

  const handleInputChange = (name, value) => {
    if (name === "image") {
      value = value ? parseInt(value, 10) : 0;
    }
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileInput) {
      const formData = new FormData();
      formData.append("document", fileInput);
      formData.append("doc_type", 0);

      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );

        setProfileData((prev) => ({
          ...prev,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          image: docUpload.data.id,
        }));
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShouldSubmit(true);
    }
  };
  return (
    <div>
      <div className="mb-5">
        <Breadcrumb directory1="Profile" />
      </div>
      <div className="row">
        <div className="col-12 col-sm-5 col-md-4 col-lg-3 mb-2">
          <div className="border p-4">
            <div className="profile-img-wrap mb-4">
              <img
                className="img-fluid"
                src={selectedImage || profileData.image_url}
                alt={profileData.first_name}
              />
            </div>
            <div className="mb-4">
              <h6 className="text-center">
                {profileData.first_name} {profileData.last_name}
              </h6>
            </div>
            <div>
              <label className="btn btn-dark w-100" htmlFor="profile-img">
                Upload New Image
              </label>
              <input
                className="d-none"
                id="profile-img"
                type="file"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-7 col-md-8 col-lg-9 mb-2">
          <div className="border p-4">
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="First Name"
                  value={profileData.first_name || ""}
                  onChange={(value) => handleInputChange("first_name", value)}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={profileData.last_name || ""}
                  onChange={(value) => handleInputChange("last_name", value)}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="email"
                  placeholder="Email"
                  value={profileData.email || ""}
                  disabled={true}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Social Security Number"
                  value={profileData.ssn || ""}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-3">
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-dark w-100"
              >
                Change Information
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
