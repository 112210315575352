import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../component/alert/Alert";
import axiosInstance from "../../API/AxiosInstance";

const ForgotPassVerify = ({ mail }) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setCompanyInfo(res.data.data.results);
    });
  }, []);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (element, index, e) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if (index > 0) {
        element.previousSibling.focus();
      }
    } else if (!isNaN(e.key)) {
      setOtp([...otp.map((d, idx) => (idx === index ? e.key : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  return (
    <div className="vh-100 w-100 position-relative">
      <Alert
        message={`OTP : ${otp.join("")}`}
        type="success"
        alertState={showAlert}
      />
      <div className="absolute-center p-2 col-12 col-sm-8 col-md-4 col-lg-3">
        <div className="w-100 p-3 primary-text fs-5 fw-bolder text-center auth-card-title">
          OTP Verification Form
        </div>
        <div className="my-4">
          <h2 className="text-center fw-bold">
            {companyInfo[0] ? companyInfo[0].site_name : "Company Name"}
          </h2>
        </div>
        <p className="text-center p-2 fade-bg mb-4">
          A six digit OTP has been sent to{" "}
          <span className="fw-500">{mail}</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between mb-3">
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  type="text"
                  className="otp-input text-center form-control"
                  maxLength="1"
                  value={data}
                  readOnly
                  onKeyDown={(e) => handleChange(e.target, index, e)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>

          <button type="submit" className="btn btn-dark w-100 mt-3">
            Submit
          </button>
        </form>
        <div className="mt-3 d-flex align-items-center justify-content-center">
          <Link>Resend OTP</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassVerify;
