import { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [userData, setUserData] = useState(() => {
    // Try to get user data from localStorage on initialization
    const storedUserData = localStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  const setUser = (data) => {
    setUserData(data);
  };

  useEffect(() => {
    // Save user data to localStorage whenever it changes
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  return (
    <DataContext.Provider value={{ userData, setUser }}>
      {children}
    </DataContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(DataContext);
};
