import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="vh-100 w-100 primary-text bg-light d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column justify-content-center">
        <h1 className="fw-600 text-center" style={{ fontSize: "100px" }}>
          404
        </h1>
        <h3 className="text-center">Page / Directory not found.</h3>
        <Link to="/" className="btn btn-dark mt-4">
          Goto Home Page
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
