import React, { useState, useEffect } from "react";
import Input from "../../component/inputs/Input";
import useInputChange from "../../utilities/useInputChange";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../API/AxiosInstance";

const Register = ({ regEmail }) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setCompanyInfo(res.data.data.results);
    });
  }, []);
  const { inputData: registerData, handleInputChange } = useInputChange();

  const [loading, setLoading] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/signup/", registerData);
      regEmail(response.data.data.email);
      if (response.statusText === "Created") {
        navigate("/account-verification");
      }
    } catch (error) {
      seterrMsg(
        error.response?.data?.errors?.email?.[0] ||
          error.response?.data?.errors?.confirm_password?.[0] ||
          error.response?.data?.errors?.ssn?.[0] ||
          error.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 w-100 position-relative">
      <div className="absolute-center p-2 col-12 col-md-8 col-lg-7">
        <div className="w-100 p-3 primary-text fs-5 fw-bolder text-center auth-card-title">
          Account Register Form
        </div>
        <div className="my-4">
          <h2 className="text-center fw-bold">
            {companyInfo[0] ? companyInfo[0].site_name : "Company Name"}
          </h2>
        </div>
        {loading ? <p className="text-center">Loading...</p> : ""}
        {errMsg ? (
          <p className="text-center text-white bg-danger p-1">{errMsg}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <Input
                type="text"
                name="first_name"
                placeholder="First Name"
                value={registerData.first_name || ""}
                onInputChange={handleInputChange}
                required={true}
              />
              <Input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={registerData.last_name || ""}
                onInputChange={handleInputChange}
                required={true}
              />
              <Input
                type="email"
                placeholder="Email"
                name="email"
                value={registerData.email || ""}
                onInputChange={handleInputChange}
                required={true}
              />
            </div>
            <div className="col-md-6">
              <Input
                type="password"
                placeholder="Password"
                name="password"
                value={registerData.password || ""}
                onInputChange={handleInputChange}
                required={true}
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                name="confirm_password"
                value={registerData.confirm_password || ""}
                onInputChange={handleInputChange}
                required={true}
              />
              <Input
                type="date"
                placeholder="Date of Birth"
                name="date"
                value={registerData.date || ""}
                onInputChange={handleInputChange}
                required={true}
              />
            </div>
          </div>
          <div className="col-12">
            <Input
              type="text"
              placeholder="Social Security Number"
              name="ssn"
              value={registerData.ssn || ""}
              onInputChange={handleInputChange}
              required={true}
            />
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-dark w-100 mt-3 fs-5">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
