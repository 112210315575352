export const genderData = [
  { title: "Select an item", value: "", disabled: true },
  { title: "Male", value: 0 },
  { title: "Female", value: 1 },
  { title: "Other", value: 2 },
];

export const adminClientShortColumn = [
  { title: "ID", key: "id" },
  { title: "First Name", key: "first_name" },
  { title: "Last Name", key: "last_name" },
  { title: "Email", key: "email" },
  { title: "SSN", key: "ssn" },
];

export const adminClientDocsColumn = [
  { title: "ID", key: "id" },
  { title: "File", key: "file" },
  // {
  //   title: "Approval Status",
  //   key: "approval_status",
  //   render: (data) => {
  //     const statusObject = approvalStatusData.find(
  //       (status) => status.value === data.approval_status
  //     );

  //     return statusObject ? <span>{statusObject.title}</span> : null;
  //   },
  // },
  { title: "Expiry Date", key: "expiry_date" },
  { title: "Reject Reason", key: "reject_reason" },
  {
    title: "Status",
    key: "status",
  },
  { title: "Document Type", key: "document_type_name" },
];

export const documentTypeColumn = [
  { title: "ID", key: "id" },
  { title: "Name", key: "name" },
  { title: "Status", key: "is_active" },
];

export const activeStatusData = [
  { title: "Select an item", value: "", disabled: true },
  { title: "True", value: true },
  { title: "False", value: false },
];

export const approvalStatusData = [
  { title: "Select an item", value: "", disabled: true },
  { title: "Pending", value: 0 },
  { title: "Approved", value: 1 },
  { title: "On Processing", value: 2 },
  { title: "Rejected", value: 3 },
];

export const statusTypeData = [
  { title: "Select an item", value: "", disabled: true },
  { title: "Pending", value: 0 },
  { title: "Approved", value: 1 },
  { title: "Rejected", value: 2 },
];

export const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "webp"].includes(extension)) {
    return 0;
  } else if (["mp4", "mkv"].includes(extension)) {
    return 1;
  } else if (extension === "pdf") {
    return 2;
  } else {
    return 3;
  }
};

export const clientDocsColumn = [
  { title: "ID", key: "id" },
  // { title: "File", key: "file" },
  { title: "Client", key: "client_name" },
  { title: "Document Type", key: "document_type_name" },
  // {
  //   title: "Approval Status",
  //   key: "approval_status",
  //   render: (data) => {
  //     const statusObject = approvalStatusData.find(
  //       (status) => status.value === data.approval_status
  //     );

  //     return statusObject ? <span>{statusObject.title}</span> : null;
  //   },
  // },
  { title: "Expiry Date", key: "expiry_date" },
  {
    title: "Document Status",
    key: "status",
    render: (data) => {
      const statusObj = statusTypeData.find(
        (status) => status.value === data.status
      );
      return statusObj ? <span>{statusObj.title}</span> : null;
    },
  },
  { title: "Reject Reason", key: "reject_reason" },
  // { title: "File Url", key: "file_url" },
];

export const documentsDataClient = [
  { title: "ID", key: "id" },
  { title: "Document Type", key: "document_type_name" },
  { title: "Expiry Date", key: "expiry_date" },
  {
    title: "Status",
    key: "status",
  },
  { title: "Reject Reason", key: "reject_reason" },
  {
    title: "Created at",
    key: "created_at",
    render: (data) => {
      const formattedDate = new Date(data.created_at).toLocaleString();
      return <span>{formattedDate}</span>;
    },
  },
];

export const clientProfileColumn = [
  { title: "First Name", key: "first_name" },
  { title: "Last Name", key: "last_name" },
  { title: "Image", key: "image_url" },
  { title: "DOB", key: "dob" },
  {
    title: "Gender",
    key: "gender",
    render: (data) => {
      const genderObject = genderData.find(
        (status) => status.value === data.gender
      );

      return genderObject ? <span>{genderObject.title}</span> : null;
    },
  },
  { title: "SSN", key: "ssn" },
  { title: "Role", key: "role" },
  {
    title: "Approval Status",
    key: "approval_status",
    render: (data) => {
      const statusObject = approvalStatusData.find(
        (status) => status.value === data.approval_status
      );

      return statusObject ? <span>{statusObject.title}</span> : null;
    },
  },
];

// export const testDocType = [
//   { title: "Pending", value: 0 },
//   { title: "Approved", value: 1 },
//   { title: "Rejected", value: 2 },
// ];
