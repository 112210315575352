import React from "react";

const Alert = ({ message, type, alertState }) => {
  return (
    <div className={`alert alert-${type} ${alertState ? "alert-visible" : ""}`}>
      {message}
    </div>
  );
};

export default Alert;
