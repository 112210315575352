import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../API/AxiosInstance";

const OtpPage = ({ mail }) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    axiosInstance.get("utility/mobile/global_settings/").then((res) => {
      setCompanyInfo(res.data.data.results);
    });
  }, []);
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index, e) => {
    if (e.key === "Backspace") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if (index > 0) {
        element.previousSibling.focus();
      }
    } else if (!isNaN(e.key)) {
      setOtp([...otp.map((d, idx) => (idx === index ? e.key : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("auth/account/verify/", {
        email: mail,
        code: otp.join(""),
      });
      if (response.statusText === "OK") {
        navigate("/login");
      }
    } catch (error) {
      seterrMsg(error.response?.data?.errors?.code?.[0]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 w-100 position-relative">
      <div className="absolute-center p-2 col-12 col-sm-8 col-md-4 col-lg-3">
        <div className="w-100 p-3 primary-text fs-5 fw-bolder text-center auth-card-title">
          OTP Verification Form
        </div>
        <div className="my-4">
          <h2 className="text-center fw-bold">
            {companyInfo[0] ? companyInfo[0].site_name : "Company Name"}
          </h2>
        </div>
        {loading ? <p className="text-center">Loading...</p> : ""}
        {errMsg ? (
          <p className="text-center text-white bg-danger p-1">{errMsg}</p>
        ) : (
          ""
        )}
        <p className="text-center p-2 fade-bg mb-4">
          A six digit OTP has been sent to{" "}
          <span className="fw-500">{mail}</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between mb-3">
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  type="text"
                  className="otp-input text-center form-control"
                  maxLength="1"
                  value={data}
                  readOnly
                  onKeyDown={(e) => handleChange(e.target, index, e)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>

          <button type="submit" className="btn btn-dark w-100 mt-3">
            Submit
          </button>
        </form>
        <div className="mt-3 d-flex align-items-center justify-content-center">
          <Link>Resend OTP</Link>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
