import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { FaEdit } from "react-icons/fa";
import Table from "../../../component/table/Table";
import axiosInstance from "../../../API/AxiosInstance";
import Modal from "../../../component/modal/Modal";
import Input from "../../../component/inputs/MaInput";
import postData from "../../../API/postData";
import { postDocumentData } from "../../../API/postDocumentData";

const SiteInfo = () => {
  const [siteInfoData, setSiteInfoData] = useState([]);
  const [addData, setAddData] = useState({});
  const [fileInput, setFileInput] = useState();
  const [addDataModalVisible, setAddDataModalVisible] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const token = `Token ${localStorage.getItem("token")}`;
  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get("utility/admin/site-info/", {
        headers: { Authorization: token },
      });
      setSiteInfoData(response.data.data);
    } catch (err) {
      console.log(err.response);
    }
  }, [token]);

  const handleInputChange = (name, value) => {
    if (name === "logo") {
      value = value ? parseInt(value, 10) : 0;
    }
    setSiteInfoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const addSiteInfo = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document", fileInput);
    formData.append("doc_type", 0);

    if (fileInput) {
      try {
        const docUpload = await postDocumentData(
          "auth/documents/upload/",
          formData
        );
        setSiteInfoData((prev) => ({
          ...prev,
          logo: docUpload.data.id,
        }));
        setAddData({
          site_name: siteInfoData.site_name,
          website_url: siteInfoData.website_url,
          email: siteInfoData.email,
          phone: siteInfoData.phone,
          address: siteInfoData.address,
          short_desc: siteInfoData.short_desc,
          facebook: siteInfoData.facebook,
          twitter: siteInfoData.twitter,
          linkedin: siteInfoData.linkedin,
          instagram: siteInfoData.instagram,
          youtube: siteInfoData.youtube,
          logo: docUpload.data.id,
        });
        // console.log("add data", addData);
        setShouldSubmit(true);
      } catch (error) {
        console.log(error);
      }
    } else if (!fileInput) {
      setAddData({
        site_name: siteInfoData.site_name,
        website_url: siteInfoData.website_url,
        email: siteInfoData.email,
        phone: siteInfoData.phone,
        address: siteInfoData.address,
        short_desc: siteInfoData.short_desc,
        facebook: siteInfoData.facebook,
        twitter: siteInfoData.twitter,
        linkedin: siteInfoData.linkedin,
        instagram: siteInfoData.instagram,
        youtube: siteInfoData.youtube,
        logo: siteInfoData.logo,
      });
      setShouldSubmit(true);
    }
  };

  const postSiteInfo = useCallback(async () => {
    try {
      const response = await postData("utility/admin/site-info/", addData);
      console.log(response);
      setShouldSubmit(false);
      setAddDataModalVisible(false);
    } catch (err) {
      console.log(err.response);
    }
  }, [addData]);

  useEffect(() => {
    fetchData();
    if (shouldSubmit) {
      postSiteInfo();
    }
  }, [fetchData, shouldSubmit, postSiteInfo, addData]);

  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb directory1="Site Info" />
        <button
          className="btn btn-dark"
          onClick={() => setAddDataModalVisible(true)}
        >
          <span className="me-2">
            <FaEdit className="mb-1" />
          </span>
          Change Info
        </button>
      </div>
      <div>
        <Table columns={siteInfoColumns} dataSource={[siteInfoData]} />
      </div>
      {/* add modal */}
      <Modal
        title="Change Site Info"
        content={
          <>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Site Name"
                  value={siteInfoData.site_name || ""}
                  onChange={(value) => handleInputChange("site_name", value)}
                />
                <Input
                  type="text"
                  placeholder="Website URL"
                  value={siteInfoData.website_url || ""}
                  onChange={(value) => handleInputChange("website_url", value)}
                />
                <Input
                  type="text"
                  placeholder="Email"
                  value={siteInfoData.email || ""}
                  onChange={(value) => handleInputChange("email", value)}
                />
                <Input
                  type="text"
                  placeholder="Phone"
                  value={siteInfoData.phone || ""}
                  onChange={(value) => handleInputChange("phone", value)}
                />
                <Input
                  type="text"
                  placeholder="Address"
                  value={siteInfoData.address || ""}
                  onChange={(value) => handleInputChange("address", value)}
                />
                <Input
                  type="text"
                  placeholder="Description"
                  value={siteInfoData.short_desc || ""}
                  onChange={(value) => handleInputChange("short_desc", value)}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder="Facebook"
                  value={siteInfoData.facebook || ""}
                  onChange={(value) => handleInputChange("facebook", value)}
                />
                <Input
                  type="text"
                  placeholder="Twitter"
                  value={siteInfoData.twitter || ""}
                  onChange={(value) => handleInputChange("twitter", value)}
                />
                <Input
                  type="text"
                  placeholder="Linkedin"
                  value={siteInfoData.linkedin || ""}
                  onChange={(value) => handleInputChange("linkedin", value)}
                />
                <Input
                  type="text"
                  placeholder="Instagram"
                  value={siteInfoData.instagram || ""}
                  onChange={(value) => handleInputChange("instagram", value)}
                />
                <Input
                  type="text"
                  placeholder="Youtube"
                  value={siteInfoData.youtube || ""}
                  onChange={(value) => handleInputChange("youtube", value)}
                />

                <div className="mb-3">
                  <label className="form-label fw-bold">Logo</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setFileInput(e.target.files[0])}
                  />
                </div>
                <img
                  src={siteInfoData.logo_url || ""}
                  alt={siteInfoData.logo_url || ""}
                  className="img-fluid mb-2"
                  style={{ maxHeight: "50px" }}
                />
              </div>
            </div>
          </>
        }
        visible={addDataModalVisible}
        onCancel={() => setAddDataModalVisible(false)}
        onOk={addSiteInfo}
      />
    </div>
  );
};

export default SiteInfo;

export const siteInfoColumns = [
  { title: "ID", key: "id" },
  { title: "Site Name", key: "site_name" },
  { title: "Website URL", key: "website_url" },
  { title: "Email", key: "email" },
  { title: "Phone", key: "phone" },
  { title: "Address", key: "address" },
  { title: "Description", key: "short_desc" },
  { title: "Facebook", key: "facebook" },
  { title: "Twitter", key: "twitter" },
  { title: "Linkedin", key: "linkedin" },
  { title: "Instagram", key: "instagram" },
  { title: "Youtube", key: "youtube" },
  { title: "Logo", key: "logo_url" },
  { title: "Created At", key: "created_at" },
  { title: "Updated At", key: "updated_at" },
];
