import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { GETData } from "../../../API/GETDataV2";
import Input from "../../../component/inputs/MaInput";
import Table from "../../../component/table/Table";
import { adminClientDocsColumn } from "../../../data/Data";
import { getData } from "../../../API/getData";
import axiosInstance from "../../../API/AxiosInstance";
import { FaDownload } from "react-icons/fa6";
import { saveAs } from "file-saver";

const ClientView = () => {
  const { id } = useParams();
  const [clientInfo, setClientInfo] = useState({});
  const [reqDocs, setReqDocs] = useState([]);
  const fetchReqDoc = async () => {
    try {
      const response = await getData("manage/admin/document-type/");
      setReqDocs(response);
    } catch (err) {
      console.log(err.response);
    }
  };
  useEffect(() => {
    fetchReqDoc();
    GETData(`manage/admin/client/${id}/`).then((res) => {
      setClientInfo(res);
    });
  }, [id]);

  const actionColumn = {
    title: "Actions",
    key: "actions",
    render: (data) => {
      return (
        <div className="d-flex align-items-center">
          <button
            className="secodary-bg p-1 border-0 me-2"
            onClick={() => saveAs(data.file_url, data.document_type_name)}
          >
            <FaDownload className="primary-text" />
          </button>
        </div>
      );
    },
  };

  const finalColumn = [...adminClientDocsColumn, actionColumn];

  const [markMsg, setMarkMsg] = useState("");
  const token = `Token ${localStorage.getItem("token")}`;
  const handleAllApproved = async () => {
    try {
      const response = await axiosInstance.get(
        `manage/admin/client-documents/${id}/mark_all_approved/`,
        {
          headers: { Authorization: token },
        }
      );
      setMarkMsg(response.data.message);
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <div>
      <div className="mb-5 d-flex align-items-center justify-content-between">
        <Breadcrumb
          directory1="Clients"
          directory2={clientInfo.first_name}
          path="/clients"
        />
        <button className="btn btn-dark" onClick={handleAllApproved}>
          {markMsg ? markMsg : "Mark All Document as Approved"}
        </button>
      </div>
      <div>
        <div className="mb-4">
          <h4 className="fw-bold">Client Information</h4>
        </div>
        <div className="basic-infos row mb-3">
          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <Input
              type="text"
              value={clientInfo.first_name || ""}
              placeholder="First Name"
              disabled={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <Input
              type="text"
              value={clientInfo.last_name || ""}
              placeholder="Last Name"
              disabled={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <Input
              type="email"
              value={clientInfo.email || ""}
              placeholder="Email"
              disabled={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <Input
              type="text"
              value={clientInfo.ssn || ""}
              placeholder="SSN"
              disabled={true}
            />
          </div>
        </div>
        <div className="document-table mb-3">
          <Table
            columns={finalColumn}
            dataSource={clientInfo.client_documents || ""}
          />
        </div>
        <label htmlFor="" className="form-label fw-bold my-3">
          Required documents
        </label>
        <div className="required-document-infos d-flex flex-wrap mb-3">
          {clientInfo.required_documents ? (
            clientInfo.required_documents.map((item, index) => {
              const docObject = reqDocs.find((doc) => doc.id === item);

              return (
                <div key={index}>
                  <p className="fw-bold border fade-bg p-1 me-3">
                    {docObject ? docObject.name : item}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="text-dark">No Data</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientView;
