import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import "../../assets/style/main-layout.css";

const MainLayout = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const SidebarSwap = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const navContentRef = useRef(null);

  useEffect(() => {
    const handleContentClick = (e) => {
      if (
        window.innerWidth <= 1025 &&
        sidebarToggle &&
        navContentRef.current.contains(e.target)
      ) {
        setSidebarToggle(false);
      }
    };

    document.addEventListener("click", handleContentClick);

    return () => {
      document.removeEventListener("click", handleContentClick);
    };
  }, [sidebarToggle]);

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 1025) {
      setSidebarToggle(false);
    }
  };

  return (
    <div className="position-relative d-flex">
      <div className="sidebar-area">
        <Sidebar toggle={sidebarToggle} toggleSidebar={handleMenuItemClick} />
      </div>
      <div
        ref={navContentRef}
        className={`nav-and-content-area ${sidebarToggle ? "w-100" : ""}`}
      >
        <div className="rounded-3">
          <Navbar onToggle={SidebarSwap} />
        </div>
        <div className="content-area">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
