import React from "react";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

const DashCardSm = ({ title, value }) => {
  return (
    <div className="col-12 col-sm-6 col-md-3 col-lg-3">
      <div className="border mb-2 dashboard-card-sm d-flex align-items-center primary-bg text-white">
        <RiMoneyDollarCircleFill />
        <div className="ms-2">
          <h6 className="mb-1">{title}</h6>
          <h5 className="mb-0">{value}</h5>
        </div>
      </div>
    </div>
  );
};

export default DashCardSm;
